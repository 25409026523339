import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/scrollTrigger.js";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";


gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

function requireAll(r) { r.keys().forEach(r); }
requireAll(require.context("./special-effects/", true, /\.js$/));

//




