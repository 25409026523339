import "slick-carousel";
import "slick-carousel/slick/slick.scss";

$(document).ready(function () {
	if ($(".slider-slick").length > 0) {
		$(".slider-slick").slick({
			dots: true,
			infinite: false,
			speed: 300,
			autoplay: true,
			autoplaySpeed: 7000,
			slidesToShow: 1,
			slidesToScroll: 1,
			adaptiveHeight: false,
			fade: false,
			centerMode: false, //
			arrows: true,
			nextArrow:
				'<button type="button" class="slick-next slick-arrow"></button>',
			prevArrow:
				'<button type="button" class="slick-prev slick-arrow"></button>',
			responsive: [
				{
					breakpoint: 1400,
					settings: {
						slidesToShow: 1,
						arrows: true,
						dots: true,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 1,
						arrows: true,
						dots: true,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 1,
						arrows: true,
						dots: true,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						centerMode: false, //
						arrows: false,
						dots: true,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 576,
					settings: {
						slidesToShow: 1,
						centerMode: false, //
						variableWidth: false,
						arrows: false,
						dots: true,
						slidesToScroll: 1,
					},
				},
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			],
		});
	}
});
