$(document).ready(function () {
	// Create a new video element
	var $video = $("<video></video>", {
		id: "we-hero-video",
		preload: "none",
		autoplay: true,
		loop: true,
		muted: true,
		playsInline: true,
		"data-mobile-vid": "https://exhibitrentals.com/wp-content/uploads/2023/01/ER_1-5.mp4",
		"data-desktop-vid":
			"https://exhibitrentals.com/wp-content/uploads/2023/01/ER_Desktop.mp4",
	});

	// Create a new source element
	var $source = $("<source/>", {
		src: "https://exhibitrentals.com/wp-content/uploads/2023/01/ER_Desktop.mp4",
		type: "video/mp4",
	});

	// Append the source element to the video element
	$video.append($source);

	// Append the video element to the body (or wherever you want it in your document)
	$(".we-soldigital_hero_video__inner").append($video);



	//HERO VIDEO
	function checkSize() {
		var $window = $(window),
			$video = $(".we-the-homepage #we-hero-video"),
			mobileVid = $video.data("mobile-vid"),
			desktopVid = $video.data("desktop-vid");

		if ($window.width() < 768) {
			// you can adjust this value
			$video.find("source").attr("src", mobileVid);
		} else {
			$video.find("source").attr("src", desktopVid);
		}

		$video[0].load();
	}

	// Execute on load
	checkSize();

	// Bind event listener
	$(window).resize(checkSize);

	//END HERO VIDEO
});

//HERO VIDEO OPTIMISATION
/* document.addEventListener("DOMContentLoaded", function () {
	var lazyVideos = [].slice.call(document.querySelectorAll("video"));

	if ("IntersectionObserver" in window) {
		var lazyVideoObserver = new IntersectionObserver(function (
			entries,
			observer
		) {
			entries.forEach(function (video) {
				if (video.isIntersecting) {
					for (var source in video.target.children) {
						var videoSource = video.target.children[source];
						if (
							typeof videoSource.tagName === "string" &&
							videoSource.tagName === "SOURCE"
						) {
							videoSource.src = videoSource.dataset.src;
						}
					}

					video.target.load();
					video.target.classList.remove("lazy");
					lazyVideoObserver.unobserve(video.target);
				}
			});
		});

		lazyVideos.forEach(function (lazyVideo) {
			lazyVideoObserver.observe(lazyVideo);
		});
	}
}); */
//END HERO VIDEO OPTIMISATION
