import "bootstrap/js/dist/modal";

$(document).ready(function () {

	// When the play button or overlay is clicked...
	$(
		".zagrebweb-youtube-video__overlay, .zagrebweb-youtube-video__play-button"
	).click(function () {
		var src =
			$(this).closest('.zagrebweb-youtube-video__inner').data("video-src") + "&autoplay=1";
		$("#videoModal").modal("show");
		$("#video").attr("src", src);
	});

	// When the modal is closed...
	$("#videoModal").on("hide.bs.modal", function (e) {
		$("#video").attr("src", "");
	});
});
